<template>
  <validation-observer ref="simpleRules">
    <b-card-code title=" Chemical List">
      <b-button
        v-show="isAdmin"
        variant="outline-primary"
        class="bg-gradient-primary mt-2 side-button"
        type="submit"
        @click="addItem"
      >
        <span class="align-middle">Add Chemical </span>
      </b-button>
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
            />
          </div>
        </b-form-group>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="dataRows"
        :is-loading="loading"
        style-class="vgt-table striped"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm }"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          position: 'bottom'
        }"
      >
        <div
          slot="emptystate"
          align="center"
        >
          No Data
        </div>
        <template
          slot="table-row"
          slot-scope="props"
        >

          <!-- Column: Status -->
          <span
            v-if="props.column.field === 'Status'"
            v-b-modal.modal-center
            :title="props.row.Komentar"
          >
            <b-badge :variant="statusVariant(props.row.Status)">
              {{ props.row.Status }}
            </b-badge>
          </span>

          <!-- Column: Action -->
          <span v-else-if="props.column.field === 'Action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none nopad"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-show="isAdmin === false"
                  @click="Restock({id:props.row.id, name: props.row.Name, quantity: props.row.Quantity, note: props.row.Keterangan, order: 'take'})"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  <span>Take</span>
                </b-dropdown-item>
                <b-dropdown-item @click="getDetail(props.row.id)">
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  <span>Detail</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-show="isAdmin"
                  @click="validateData({id:props.row.id, name: props.row.Name, quantity: props.row.Quantity, note: props.row.Keterangan, order: 'edit'})"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-show="isAdmin"
                  @click="Restock({id:props.row.id, name: props.row.Name, quantity: props.row.Quantity, note: props.row.Keterangan, order: 'restock'})"
                >
                  <feather-icon
                    icon="EditIcon"
                    class="mr-50"
                  />
                  <span>Restock</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-show="isAdmin"
                  @click="validateData({id:props.row.id, name: props.row.Name, quantity: props.row.Quantity, order: 'delete'})"
                >
                  <feather-icon
                    icon="Trash2Icon"
                    class="mr-50"
                  />
                  <span>Remove</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template
          slot="pagination-bottom"
          slot-scope="props"
        >
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap ">
                Showing 1 to
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5','15','30','50']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
              />
              <span class="text-nowrap"> of {{ totalPage }} entries </span>
            </div>

            <div>
              <template>
                <b-button
                  variant="outline-primary"
                  class="bg-gradient-primary mt-2"
                  pill
                  :disabled="!prev"
                  size="sm"
                  @click="prev ? getDisposisi('prev') : ''"
                >
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                  />
                </b-button>
              </template>
              <template>
                <b-button
                  variant="outline-primary"
                  class="bg-gradient-primary mt-2 ml-1"
                  pill
                  :disabled="!next"
                  size="sm"
                  @click="next ? getDisposisi('next') : ''"
                >
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                  />
                </b-button>
              </template>
            </div>
          </div>
        </template>
      </vue-good-table>

      <b-modal
        id="modal-center"
        ref="modal"
        v-model="modalShow"
        centered
        title="Form Add Chemical"
        cancel-title="Cancel"
        ok-title="Add"
        @ok="validationForm"
      >
        <validation-provider
          #default="{ errors }"
          name="Name"
          rules="required"
        >
          <b-form-group
            label="Name"
            label-for="Name"
          >
            <b-form-input
              id="Name"
              v-model="name"
              placeholder="Input Name"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Quantity"
          rules="required||numeric"
        >
          <b-form-group
            label="Quantity"
            label-for="Quantity"
          >
            <b-form-input
              id="Quantity"
              v-model="quantity"
              placeholder="Input Quantity"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="Keterangan"
          rules="required"
        >
          <b-form-group
            label="Keterangan"
            label-for="Keterangan"
          >
            <b-form-input
              id="Keterangan"
              v-model="note"
              placeholder="Input Keterangan"
            />
          </b-form-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-modal>

      <b-modal
        id="modal-detete"
        ref="modaldelete"
        v-model="modalDelete"
        centered
        :title="`${selectedItem.order === 'edit' ? 'Edit' : 'Remove'}  Chemical`"
        cancel-title="Cancel"
        :ok-title="selectedItem.order === 'delete' ? 'Remove' : 'Save'"
        @ok="execute"
      >
        <div v-show="selectedItem.order === 'edit'">
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-group
              label="Name"
              label-for="Name"
            >
              <b-form-input
                id="Name"
                v-model="name"
                placeholder="Input Name"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            v-show="false"
            #default="{ errors }"
            name="Quantity"
            rules="required||numeric"
          >
            <b-form-group
              label="Quantity"
              label-for="Quantity"
            >
              <b-form-input
                id="Quantity"
                v-model="quantity"
                placeholder="Input Quantity"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="Keterangan"
            rules="required"
          >
            <b-form-group
              label="Keterangan"
              label-for="Keterangan"
            >
              <b-form-input
                id="Keterangan"
                v-model="note"
                placeholder="Input Keterangan"
              />
            </b-form-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </div>
        <span v-show="selectedItem.order === 'delete'"> Remove item <b> {{ selectedItem.name }} </b> </span>
      </b-modal>

      <validation-observer ref="restock">
        <b-modal
          id="modal-restock"
          ref="modalrestock"
          v-model="modalRestock"
          centered
          :title="`${selectedItem.order === 'restock' ? 'Restock' : 'Take'} Chemical`"
          cancel-title="Cancel"
          ok-title="Save"
          @ok="execute"
        >
          <div>
            <validation-provider
              #default="{ errors }"
              name="Quantity"
              :rules="`required||numeric||max_value:${selectedItem.quantity}`"
            >
              <b-form-group
                label="Quantity"
                label-for="Quantity"
              >
                <b-form-input
                  id="Quantity"
                  v-model="quantity"
                  placeholder="Input Quantity"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

            <validation-provider
              #default="{ errors }"
              name="Keterangan"
              rules=""
            >
              <b-form-group
                label="Keterangan"
                label-for="Keterangan"
              >
                <b-form-input
                  id="Keterangan"
                  v-model="note"
                  placeholder="Input Keterangan"
                />
              </b-form-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </b-modal>
      </validation-observer>

      <b-modal
        id="modal-detail"
        ref="modaldetail"
        v-model="modalDetail"
        centered
        :title="`Detail Chemical`"
        ok-title="Ok"
        ok-only
        @ok="!modalDetail"
      >
        <Chart
          :date="detailDate"
          :value="detailValue"
        />

        <div
          v-for="item in detail"
          :key="item.id"
          class="mt-2 mb-2"
        >
          <app-timeline>
            <app-timeline-item
              :title="`${item.user ? item.user.username : '-'} - ${item.type}`"
              :subtitle="`Total: ${item.qty}`"
              :time="dateFormat(item.item.updated_at)"
            />
          </app-timeline>
        </div>
      </b-modal>

    </b-card-code>
  </validation-observer>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-unused-components */
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BLink,
  VBTooltip,
  VBModal,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, numeric } from '@validations'
import axios from '@axios'
import dayjs from 'dayjs'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Chart from './diagram.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    numeric,
    BLink,
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    VBModal,
    VBTooltip,
    AppTimeline,
    AppTimelineItem,
    Chart,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  data() {
    return {
      pageTitle: '',
      loading: true,
      pageLength: 10,
      dir: false,
      totalPage: 0,
      totalRow: 0,
      page: 0,
      fromPage: 0,
      toPage: 0,
      modalShow: false,
      modalDelete: false,
      modalRestock: false,
      modalDetail: false,
      isAdmin: false,
      name: '',
      note: '',
      prev: '',
      next: '',
      quantity: '',
      selectedItem: {},
      detail: {},
      detailDate: {},
      detailValue: {},
      columns: [
        {
          label: 'Name',
          field: 'Name',
        },
        {
          label: 'Quantity',
          field: 'Quantity',
        },
        {
          label: 'Created',
          field: 'CreatedAt',
        },
        {
          label: 'Updated',
          field: 'UpdatedAt',
        },
        {
          label: 'Keterangan',
          field: 'Keterangan',
        },
        {
          label: 'Action',
          field: 'Action',
        },
      ],
      dataRows: [
        {
          Name: '',
          Quantity: '',
          CreatedAt: '',
          UpdatedAt: '',
          authorize: false,
          Status: '',
          Action: '',
          Komentar: [],
        },
      ],
      searchTerm: '',
      Status: [
        {
          1: 'Procces',
          2: 'Proses',
          3: 'Rejected',
          4: 'Resigned',
          5: 'Applied',
        },
        {
          1: 'light-primary',
          2: 'light-success',
          3: 'light-danger',
          4: 'light-warning',
          5: 'light-info',
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Procces: 'light-primary',
        Proses: 'light-success',
        Failed: 'light-danger',
        Resigned: 'light-warning',
        Process: 'light-info',
        /* eslint-enable key-spacing */
      }
      return status => statusColor[status]
    },
  },
  watch: {
    pageLength() {
      this.getDisposisi()
    },
  },
  created() {
    if (store.state.userData.user.role === '1') {
      this.isAdmin = true
    } else {
      this.isAdmin = false
    }
  },
  mounted() {
    this.getDisposisi()
  },
  methods: {
    detailDisposisi(e) {
      window.location.href = `detail-disposisi/${e}`
    },
    validationForm(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.add()
          this.$refs.modal.hide()
        }
      })
    },
    addItem() {
      this.nama = ''
      this.quantity = ''
      this.note = ''
      this.modalShow = true
    },
    validateData(val) {
      this.modalDelete = !this.modalDelete
      this.name = val.name
      this.quantity = val.quantity
      this.note = val.note
      this.selectedItem = val
    },
    Restock(val) {
      this.modalRestock = !this.modalRestock
      this.name = val.name
      this.quantity = ''
      this.note = ''
      this.selectedItem = val
      if (val.order === 'detail') {
        this.detail()
      }
    },
    execute(bvModalEvt) {
      bvModalEvt.preventDefault()
      this.$refs.restock.validate().then(success => {
        if (success) {
          if (this.selectedItem.order === 'delete') {
            this.delete()
          } else if (this.selectedItem.order === 'edit') {
            this.edit()
          } else if (this.selectedItem.order === 'restock') {
            this.add('restock')
          } else if (this.selectedItem.order === 'take') {
            this.add('take')
          } else if (this.selectedItem.order === 'detail') {
            this.detail(this.selectedItem.id)
          }

          this.$refs.modalrestock.hide()
          this.$refs.modalDelete.hide()
          this.$refs.modalShow.hide()
        }
      })
    },
    async delete() {
      await axios
        .delete(`item/${this.selectedItem.id}`)
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          this.getDisposisi()
        })
        .catch(error => {
          this.errorLog = error
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },
    async edit() {
      await axios
        .put('item', {
          id: this.selectedItem.id,
          nama: this.name,
          jumlah: Number(this.quantity),
          keterangan: this.note,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          this.getDisposisi()
        })
        .catch(error => {
          this.errorLog = error
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    async getDetail(val) {
      const { data } = await axios.get(`item/consumable/detail/${val}`)
      this.modalDetail = true
      this.detail = data.data
      this.detailDate = data.data.map(e => dayjs(e.updated_at).format('DD-MM-YYYY'))
      this.detailValue = data.data.map(e => e.total)
    },

    async getDisposisi(val) {
      const defaultUrl = '/item/consumable'
      let url = ''

      if (val === 'next') {
        url = `${defaultUrl}${new URL(this.next).search}`
      } else if (val === 'prev') {
        url = `${defaultUrl}${new URL(this.prev).search}`
      } else if (val) {
        if (val.order === 'get2') {
          url = `/item/consumable?page=${val.page}`
        }
      } else {
        url = defaultUrl
      }

      const { data } = await axios.get(url, {
        params: {
          // page: this.page === 0 ? null : this.page,
        },
      })
      this.prev = data.prev_page_url
      this.next = data.next_page_url
      this.totalPage = data.total
      this.currentPage = data.current_page
      // this.totalRow = data.per_page
      this.fromPage = data.from
      this.toPage = data.to
      this.pageLength = data.per_page
      if (data.total !== 0) {
        this.dataRows = data.data.map(e => ({
          id: e.id,
          Name: e.nama || '-',
          Quantity: e.jumlah || '-',
          CreatedAt: dayjs(e.created_at).format('DD-MM-YYYY') || '-',
          UpdatedAt: dayjs(e.updated_at).format('DD-MM-YYYY') || '-',
          Keterangan: e.keterangan || '-',
          Action: '',
        }))
      } else {
        this.dataRows = []
      }
      this.loading = false
      //   .catch(error => {
      //     console.log(error)
      //   })
    },

    async add(val) {
      let url = ''
      if (val === 'restock') {
        url = 'item/consumable/restock'
      } else if (val === 'take') {
        url = 'item/consumable/borrow'
      } else {
        url = 'item/consumable/add'
      }
      await axios
        .post(url, {
          id: this.selectedItem.id,
          nama: this.name,
          jumlah: Number(this.quantity),
          keterangan: this.note,
        })
        .then(response => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Success',
                icon: 'InfoIcon',
                text: response.data.message,
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            },
          )
          this.getDisposisi()
        })
        .catch(error => {
          this.errorLog = error
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Error',
                icon: 'InfoIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            },
          )
        })
    },

    dateFormat(time) {
      const formatted = dayjs(time).format('MMMM D, YYYY')
      if (formatted === 'Invalid Date') {
        return '-'
      }

      return formatted
    },
  },
}
</script>

<style lang="scss" >
@import "@core/scss/vue/libs/vue-good-table.scss";
.nopad {
  padding: 0;
}
.link-no {
  border-bottom: solid 1px #c5c5c5;
  padding-bottom: 3px;
  cursor: pointer;
}
</style>
